:root {

  --logo_col: #ffedf8;
  --neon: #ff0099;
  --background1: #2d304a;
  --background2: #25283d;
    --clr-white: #FFFFFF;
    --clr-black: #202020;
  }

.checker_popup {
  width: 130px;
  height: 50px;
  align-self: center;
  color: --background1;
  border: none;
  border-radius: 4px;
  border-color: #ff0099;

  /* ------------------------------------------------------ */
  /* -- Popup TEXT -- */
  font-family: "Play";
  font-size: 1.10em;
  font-weight: 600;
  text-align: center;
  background-color: var(--logo_col);
  margin-bottom: 7px;
  position: absolute;
  z-index: 999;
  margin-top: 125px;
}

.mint_popup {
  width: 130px;
  height: 50px;
  align-self: center;
  color: --background1;
  border: none;
  border-radius: 4px;
  border-color: #ff0099;

  /* ------------------------------------------------------ */
  /* -- Popup TEXT -- */
  font-family: "Play";
  font-size: 1.10em;
  font-weight: 600;
  text-align: center;
  background-color: var(--logo_col);
  margin-bottom: 7px;
  position: absolute;
  z-index: 999;
  margin-top: 472px;
}
  
  .checker_popup::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ffedf8;
    position: absolute;
    top: -10px;
    right: 10px;
  }
  
  .checker_popup p {
    text-align: center;
    margin: 0;
    padding: 16px;
  }

  .mint_popup::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ffedf8;
    position: absolute;
    top: -10px;
    right: 10px;
  }
  
  .mint_popup p {
    text-align: center;
    margin: 0;
    padding: 16px;
  }

  .connectArea {
    display: flex;
    flex-direction: column;
    justify-content: center;

  }
  
.submit_button {
    width: 111px;
    height: 50px;
    align-self: center;
    cursor: pointer;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    background-color: var(--neon);
    margin-bottom: 14px;
  }

  .submit_button:hover {
    background-color: #eca2ce;
  }

  .submit_button_blocked {
    width: 111px;
    height: 50px;
    align-self: center;
    cursor: not-allowed;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    background-color: var(--neon);
    margin-bottom: 14px;
    opacity: 0.5;
    pointer-events: none;
  }

  .app {
    background: linear-gradient(to bottom, #2d304a, #202020);
  }

  .imageText {
    color: --logo_col;
  }
  
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Play:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {

--logo_col: #ffedf8;
--neon: #ff0099;
--background1: #2d304a;
--background2: #25283d;
  --clr-white: #FFFFFF;
  --clr-black: #202020;
  --clr-blue: #6C63FF;
  --clr-blue-2: #4c46b6;
}

.description-copy {
  color: var(--neon);
  font-family: "Play";
  font-size: 1.10em;
  font-weight: 400;
  inline-size: 307px;
  margin-top: 2vmin;
}

.Countdown-toMint {
  color: var(--neon);
    text-align: center;
    font-family: "Play";
    font-size: 1.10em;
    margin-top: 2vmin;
}

.Countdown-copy {
  color: var(--neon);
  font-family: "Play";
  font-size: 3em;
  font-weight: 400;
  inline-size: 260px;
  margin-top: 3vmin;
  /*margin-bottom: -6vmin;*/
  text-align: center;
}
.Countdown-text-copy {
  color: var(--logo_col);
  font-size: 24px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.Countdown-box {
  margin-top: 4vmin;
  border: 1px solid var(--neon);
  padding: 1px 27px;
}

body {
  margin: 0;
  font-family: "Play";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* ------------------------------------------------------ */
/* -- NAVIGATION -- */

nav {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}

.nav__brand {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px;
}

.nav__brand h1 {
  color: var(--neon);
  font-size: clamp(1.75em, 4vw, 2.5em);
  font-weight: 800;
}

.nav__connect {
  width: 175px;
  height: 50px;
  margin: 0 auto;

  background-color: var(--neon);
  color: var(--clr-white);

  border: none;
  border-radius: 4px;

  font-family: "Play";
  font-size: 1.10em;
  font-weight: 600;

  cursor: pointer;
  transition: all 250ms ease;
}

.nav__connect:hover {
  background-color: #eca2ce;
}

/* ------------------------------------------------------ */
/* -- FORM -- */

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;

  min-height: 72vh;
}

form {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  margin: 0 25px
}

form input[type="text"] {
  width: 315px;
  height: 50px;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid var(--neon);
  border-radius: 4px;
  font-size: 0.90em;
}

form input[type="submit"] {
  background-color: var(--neon);
  color: var(--clr-white);

  width: 250px;
  height: 50px;
  margin: 10px 0;
  padding: 10px;

  border: none;
  border-radius: 4px;

  font-family: "Play";
  font-size: 1.10em;
  font-weight: 400;

  cursor: pointer;
  transition: all 250ms ease;
}

form input[type="submit"]:hover {
  background-color: var(--neon);
}

/* ------------------------------------------------------ */
/* -- IMAGE -- */

.image {
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 90%;
  width: 328px;
  height: 457px;
  margin: 0 25px;
  border: 3px solid var(--neon);
  border-radius: 4px;
  color: var(--logo_col);

  overflow: hidden;
  margin-bottom: 12vmin;
}

.image img {
  width: 512px;
  height: 512px;
  border-radius: 6px;
}

.image__placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  color: --logo_col;
}

.image__placeholder p {
  margin: 0 10px;
}

/* ------------------------------------------------------ */
/* -- TEXT -- */
p {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .form {
    flex-direction: column;
  }
}
:root {

    --logo_col: #ffedf8;
    --neon: #ff0099;
    --background1: #2d304a;
    --background2: #25283d;
      --clr-white: #FFFFFF;
      --clr-black: #202020;
      --clr-blue: #6C63FF;
      --clr-blue-2: #4c46b6;
    }

.faq {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .faq-item {
    border: 1px solid var(--neon);
    border-radius: 0.5rem;
    padding: 1rem;
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: #fff;
  }
  
  .faq-answer {
    margin-top: 1rem;
    color: #fff;
  }
  
  
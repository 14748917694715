.footer-desktop {
    height: 16vmin;
    padding-left: 26vmin;
    padding-right: 26vmin;
    box-sizing: border-box;
    justify-content: space-between;
    max-height: 251px;
    display: flex;
}

.logo-container {
    display: flex;
    align-items: center;
    margin: 6vmin;
}

.disclaimer {
    margin-left: 3vmin;
    font-size: 10px;
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    line-height: 159%;
    color: rgba(247, 247, 255, 1);
    word-wrap: break-word;
}

.social-icons-footer-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* margin-right: 70vmin; */
}

.set-icone-social-orizz {
    display: block;
    margin-left: 3vmin;
    margin-right: 3vmin;
    width: 5vmin;
}

.set-icone-social-orizz:hover {
    cursor: pointer;
}